// //スライダー系
// $(function() {
//   // init
//   workSlider();

//   //事例スライダー
//   function workSlider() {
//     let workSlider = $('[data-js="workSlider"]');
//     let count = workSlider.length;

//     if( count != 0 ) {
//       workSlider.slick({
//         centerMode: false,
//         infinite: true,
//         autoplay: true,
//         autoplaySpeed: 5000,
//         speed: 1000,
//         slidesToShow: 3,
//         slidesToScroll: 2,
//         swipeToSlide: true,
//         arrows: true,
//         centerPadding: 0,
//         pauseOnHover: false,
//         dots: true,
        
        
//       });
//     }
//   }



// });